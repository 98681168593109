@import 'src/utils/utils';

.alert {

  &:not(.alert-danger):not(.alert-warning):not(.alert-success):not(.alert-hide):not(.alert-show) {
    @include Shadow-1;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    margin-left: auto;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: 0;
    border-radius: 15px;
    color: $Main-1;
    background-color: $Main-2;
    gap: 10px;

    @media(min-width: $md) {
      gap: 15px;
    }

    &:before {
      position: relative;
      width: 28px;
      height: 28px;
      margin-bottom: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      flex-shrink: 0;
      content: '';

      @media(min-width: $md) {
        width: 38px;
        height: 38px;
      }
    }

    .alert {

      &__container {
        @include Text-14-reg;

        p {
          margin: 0;
        }

        b {
          @include Text-16-bold;
        }

        a {
          text-decoration: underline;
          color: $Corporate-2;
          text-decoration-color: transparent;
          transition: text-decoration-color 0.3s;
      
          &:hover {
            @media(min-width: $lg) {
              text-decoration-color: $Corporate-2;
            }
          }
        }
      }

      &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 50%;
        color: $Main-1;
        background-color: $Main-4;
        transition:
          transform 0.5s,
          color 0.3s;
        outline: none;
        cursor: pointer;

        &:hover {
          @media(min-width: $lg) {
            color: $Corporate-2;
            transform: rotate(90deg);
          }
        }

        span {
          position: relative;
          display: inline-block;
          width: 14px;
          height: 2px;
          background: transparent;
          vertical-align: bottom;

          @media(min-width: $md) {
            width: 16px;
          }

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentcolor;
            content: '';
            transform: rotate(45deg);

            @media(min-width: $md) {
              height: 2px;
            }
          }

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentcolor;
            content: '';
            transform: rotate(-45deg);

            @media(min-width: $md) {
              height: 2px;
            }
          }
        }
      }
    }
  }

  &--green {

    &:before {
      background-image: url('../assets/images/icon-success.svg');
    }
  }

  &--red {

    &:before {
      background-image: url('../assets/images/icon-error.svg');
    }
  }

  &--grey {

    &:before {
      background-image: url('../assets/images/icon-exclam.svg');
    }
  }

  &--bounce {
    animation: bounce 0.6s;
  }

  &--back-bounce {
    transition: opacity 2.5s;
    opacity: 0;
  }
}
